@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

/*#4D089A - purple */
/*#323EDD - blue */
/*#DC2ADE - pink */
/*#E8F044 - yellow */

.App {
  text-align: center;
  width: 100%;
  display: block;
  position: relative;
  color: white;
  min-height: 100vh;
}
.App .App-inner {
  border: 30px solid #dc2ade;
  background-color: #4d089a;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /*bottom: 0;*/
  min-height: calc(100% - 60px);
}

h1 {
  font-family: 'Lobster', cursive;
  font-size: 80px;
  color: #e8f044;
}
.uploader {
}
.uploader .img-wrapper {
  max-width: 400px;
  max-height: 500px;
  width: 100%;
  height: 100%;
  margin: auto;
  border: 20px solid #e8f044;
  display: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.uploader .img-wrapper.img-uploaded {
  display: block;
}
.uploader .img-wrapper img.uploaded-pic {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.uploader .img-wrapper img.semen {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  max-width: 50%;
  /*margin: auto;*/
  /*opacity: 0;*/
  /*transition: all 0.4s ease-in;*/
}
.uploader input.upload {
  padding: 20px;
  margin: auto;
  display: block;
}

.facecanvas {
  position: absolute;
  top: 0;
  /*right: 0;*/
  /*bottom: 0;*/
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.helper-message {
  position: absolute;
  background-color: #dc2ade;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
